import { $count, $isuuid, $length, $ok, $partial, $string, $UUID } from "foundation-ts/commons";
import { $uuid } from "foundation-ts/crypto";
import { TSError } from "foundation-ts/tserrors";
import { Resp, RespType, Verb } from "foundation-ts/tsrequest";
import { Nullable, UUID } from "foundation-ts/types";

import { ServiceSupplier } from "g1-commons/lib/doxecureTypes";
import { ActivateUserBody, ActivationUserDto, CreateUserBody, RegistrationDto, UserDto } from "g1-commons/lib/doxecureClientTypes";

import { config } from "../config/config";
import apiClient, { assertResponse } from "./apiClient";

// TODO: verify auth/bca/login on the back end and put that in the Doxecure client
export const authenticate = async (username: string, password: string, admin: Nullable<boolean>) => {
  const body = {
    username: username,
    password: password
  } ;
  if ($ok(admin) && admin) {
    body["admin"] = 1 ;
  }

  return apiClient.request("auth/bca/login", Verb.Post, undefined, [200], body).then(([ret, _status]) => ret) ;
};

export const register = async (user: UserDto):Promise<RegistrationDto> => {
    if ($ok(user.apid)) { throw "register(): user.apid must not be set to register a new user" ; }
    const [body, count] = $partial<UserDto, CreateUserBody>(user, {
        properties:[
            'lastName', 'firstName', 'email', 'phoneNumber', 
            'organizationName', 'vatNumber', 'organizationAddress', 'billingAddress',
            'password', 'oldPassword', 'verifyPassword'
        ]
    }) ;
    
    if (!count) { throw "register(): empty user creation body" ; }

    return apiClient.register(ServiceSupplier.BCA, body).then(([registration, status]) => assertResponse(registration, status)) ;
};

// TODO: verify auth/bca/recover on the back end and put that in the Doxecure client
export const recover = async (email: string) => {
  return apiClient.request("auth/bca/recover", Verb.Post, undefined, [200], {email: email}).then(([ret, _status]) => ret) ;
};

export function isLoggedIn():boolean { console.log("isLoggedIn => " + $ok(getUserUuid())); return $ok(getUserUuid()) ; } // never throws here

export function getUserUuid(throwingMethod?:Nullable<string>):UUID|null {
    let userID:UUID|null = null ;
    if (typeof document !== 'undefined') {
        const arrayValue = document.cookie?.split("; ")?.find(item => item.startsWith("id-cookie="))?.split('=') ;
        if ($count(arrayValue) >= 2) { userID =  $UUID(arrayValue![1]) ; }
    }
    if ($length(throwingMethod)) {
        let s = throwingMethod! ;
        if (s.includes('()')) { s = s + ' function' ; }
        throw `${s} cannot get a valid user's profileID from getUserUuid()` ;
    }
    return userID ;
}

export const authenticateWithProvider = async (provider: ServiceSupplier, userId?: UUID) => {
    if (!$ok(config[provider.toLowerCase()]))
        return ;

    const state = $uuid() ;
    window.localStorage.setItem("state", state) ;
    const nonce = $uuid() ;
    window.localStorage.setItem("nonce", nonce) ;
    window.localStorage.setItem("provider", provider) ;
    if ($ok(userId)) {
        window.localStorage.setItem("associationId", userId!) ;
    }

    const authURL = config[provider.toLowerCase()].authenticationURL ;
    const clientId = config[provider.toLowerCase()].clientId ;
    const scopes = (config[provider.toLowerCase()].scopes as string).replace(/_/g, " ");
    const link = document.createElement('a') ;
    link.href = `${authURL}?client_id=${clientId}&response_type=code&state=${state}&nonce=${nonce}&scope=${scopes}&redirect_uri=${config.redirectURI}` ;
    link.rel = 'noopener noreferrer' ;    
    link.click() ;        
}

export const getProviderUserInfo = async (provider: ServiceSupplier, code: string, nonce: string) => {
    return await apiClient.request(`/${provider}/userInfo?code=${code}&nonce=${nonce}`, Verb.Get) ;
}

export const associateProviderChannel = async (userId: UUID, provider: ServiceSupplier, code: string, nonce: string) => {
    return await apiClient.setProviderChannel(userId, provider, code, nonce) ;
}

export const deleteProviderChannel = async (userId: UUID, provider: ServiceSupplier) => {
    return await apiClient.setProviderChannel(userId, provider, '', '', false) ;
}

export const logout = async () => {
    const [_ret, status] = await apiClient.request("auth/logout", Verb.Get) ;
    return status === Resp.OK ;
}

export const checkActivationToken = async (token: string): Promise<[ActivationUserDto|string, Resp]> => {
    return await apiClient.checkActivationToken(ServiceSupplier.BCA, token);
}

export const activateUser = async (body: ActivateUserBody): Promise<UUID|string|null> => {
    return await apiClient.activateUser(ServiceSupplier.BCA, body).then(([ret, status]) => assertResponse(ret, status)) ;
}

export const recoverPassword = async (body: ActivateUserBody): Promise<UUID|string|null> => {
    return await apiClient.recoverPassword(ServiceSupplier.BCA, body).then(([ret, status]) => assertResponse(ret, status)) ;
}

export const sendReinitializationPasswordMail = async (email: string): Promise<void> => {
    return await apiClient.sendReinitializationPasswordMail(ServiceSupplier.BCA, email) ;
}

export const sendAdminOTP = async (userId: Nullable<UUID>): Promise<boolean> => {
    if (!$isuuid(userId)) {
        throw 'sendAdminOTP(): userId should be a valid UUID" ;'
    }
    const [ret, status] = await apiClient.request(`user/${userId}/otp`, Verb.Post, RespType.String, [Resp.Created]) ;
    if (status === Resp.Unauthorized) {
        throw new TSError("Vous n'êtes pas autorisé à accéder à cette page", status);
    }
    return (status === Resp.Created) && ($UUID($string(ret)) === userId) ;
}

export const checkAdminOTP = async (userId: Nullable<UUID>, otp: string): Promise<boolean> => {
    if (!$isuuid(userId)) {
        throw 'checkAdminOTP(): userId should be a valid UUID" ;'
    }
    const [ret, status] = await apiClient.request(`user/${userId}/otp?otp=${otp}`) ;
    if (status === Resp.Unauthorized) {
        throw new TSError("Vous n'êtes pas autorisé à accéder à cette page", status);
    }

    return (status === Resp.OK) && ($UUID($string(ret)) === userId) ;
}