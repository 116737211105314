import { $length, $ok, $string } from "foundation-ts/commons";
import { $bool } from "foundation-ts/tsparser";
import { Resp } from "foundation-ts/tsrequest";
import { Nullable } from "foundation-ts/types";
import { $inspect } from "foundation-ts/utils";

import { G1 } from "g1-commons/lib/doxecureClient";
import { PasswordBody } from "g1-commons/lib/doxecureTypes";
import { optlog } from "../utils/functions";

export const DoxecureRoot = $string(process.env.DOXECURE_API_PATH) ;

if (!DoxecureRoot.length) { 
    throw "Environment variable 'DOXECURE_API_PATH' is not defined." ; 
}

export interface ChangePasswordFormValues extends PasswordBody {
    oldPassword: string;
    password: string;
    verifyPassword: string;
} ;
export type UpdateSignatureAction = 'signed' | 'refused' ;
  
const apiClient = new G1(DoxecureRoot!, { debug:$bool(process.env.DOXECURE_CLIENT_DEBUG)}) ;

console.log('apiClient debug mode', apiClient.debug) ;

export async function g1call<T>(title:string, call:(clientObject:G1)=>Promise<Nullable<T>>):Promise<Nullable<T>> {
    let ret:Nullable<T> = undefined ;
    try { ret = await call(apiClient) ;}
    catch(e) { console.log(`Doxecure API request ${title} did catch an exception`, e) ; ret = undefined ; }
    return ret ;
}

export function assertResponse<T>(response:T, status:Resp, returnedLog?:Nullable<string>):NonNullable<T> {
    if (!$ok(response)) { throw new Error(status.toString()); }
    if ($length(returnedLog)) {
        optlog(`${returnedLog} response:${$inspect(response)}`)
    }
    return response!;
}

export default apiClient;
