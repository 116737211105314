import React from "react";
import { Center, Alert, AlertIcon, AlertTitle, AlertDescription } from "@chakra-ui/react";

interface ErroBoxProps {
    title: string;
    message: string;
}

export const ErrorBox = (props: ErroBoxProps) => {
    const { title, message } = props ;
    return(
        <Center>
            <Alert 
                status='error'
                variant='subtle'
                flexDirection='column'
                alignItems='center'
                justifyContent='center'
                textAlign='center'
                height='200px'              
            >
                <AlertIcon boxSize='40px' mr={0} />
                <AlertTitle mt={4} mb={1} fontSize='lg'>
                    {title}
                </AlertTitle>
                <AlertDescription maxWidth='sm'>
                    {message}
                </AlertDescription>
            </Alert>
        </Center>
    );
  };
  
