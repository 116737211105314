import { Box, UseToastOptions } from "@chakra-ui/react";

import { $trim } from "foundation-ts/strings";
import { Nullable } from "foundation-ts/types";

export type ToastType = 'success' | 'warning' | 'error' | 'info'

export interface ToastInfo {
    type:ToastType ;
    text:string ;
}

export const toastOptions = (type:ToastType, text: Nullable<string>, callback?:()=>void): UseToastOptions => ({
    title: _calculateText(text, __defaultToastMessage[type]),
    status: type,
    position: "top",
    duration: 9000,
    isClosable: true,
    onCloseComplete:callback
}) ;

export const toastInfo           = (msg:ToastInfo, callback?:()=>void): UseToastOptions => toastOptions(msg.type, msg.text, callback) ;
export const defaultSuccessToast = (text: Nullable<string>, callback?:()=>void): UseToastOptions => toastOptions('success', text, callback) ;
export const defaultErrorToast   = (text: Nullable<string>, callback?:()=>void): UseToastOptions => toastOptions('error',   text, callback) ;
export const defaultWarningToast = (text: Nullable<string>, callback?:()=>void): UseToastOptions => toastOptions('warning', text, callback) ;
export const defaultInfoToast    = (text: Nullable<string>, callback?:()=>void): UseToastOptions => toastOptions('info',    text, callback) ;


function _calculateText(t:Nullable<string>, defaultText:string):string {
    t = $trim(t) ;
    if (!t.length) { t = defaultText ; }
    else if (!t.endsWith('.')) { t += '.' ; }
    return t ;
}

const __defaultToastMessage:{[key in ToastType]:string} = {
    success: "Opération effectuée.",
    error:   "Impossible de continuer l'action en cours.",
    warning: "Un événement s'est produit durant l'opération qui doit attirer votre attention.",
    info:    "Informations concernant l'opération effectuée."
} ;