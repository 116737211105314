import { SectionCategory } from "g1-commons/lib/doxecureTypes" ;

export const StandarDateFormat = "%d/%m/%Y%[ à %H:%M%]" ;
export const LongDateFormat    = "%A, %e %B %Y%[ à %H:%M%]" ;

export interface CustomItemType {
    hover?: { bg: string, color: string } ;
    select?: { bg: string, color: string } ;
    borderTop?: string ;
    borderTopColor?: string ;
    iconColor?: string ;
} ;

export interface ActionButton<T> {
    title: string ;
    variant: ButtonVariant ;
    onSubmit: (data: T) => Promise<void> ;
}

export enum NeededPersonRole {
    Sender = 0,
    Signer
} ;

export const PasswordDownLimit = 4 ;
export const PasswordUpLimit = 64 ;

export const MandatoryEmail = "Courriel obligatoire" ;
export const MandatoryPassword = `Mot de passe d'au moins ${PasswordDownLimit} et au plus ${PasswordUpLimit} caractères obligatoire` ;

export type Component = "global" | "form" | "connection" | "sidebar" | "topbar" | "connection.form" ;
export type ListComponent = "" | "canceled" | "certificates" | "clouds" | "contacts" | "documents" | "draft" | "expeditors" | "expired" | "recipients" | "refused" | "signed" | "tobeapproved" | "tobesigned" | "archived";
export type ButtonVariant = "normal" | "important" | "optional" | "alternate" | "cancel" | "delete" | "ok" ;

type componentMapType = Map<SectionCategory, { component?: ListComponent }>;

export const componentMap: componentMapType = new Map();

componentMap.set("expired", { component: "expired" }); // Dépassés
componentMap.set("urgent", { }); // Urgents
componentMap.set("to-be-signed", { component: "tobesigned" }); // A signer
componentMap.set("to-be-approved", { component: "tobeapproved" }); // A valider
componentMap.set("signed", { component: "signed" }); // Signés
componentMap.set("draft", { component: "draft" }); // Brouillons
componentMap.set("refused", {  component: "refused" }); // Refusés
componentMap.set("canceled", { component: "canceled" }); // Annulés
componentMap.set("archived", { component: "archived" }); // Archivés

type sectionMapType = Map<SectionCategory, { title: string; description: string }>;
export const sectionMap: sectionMapType = new Map();
sectionMap.set("expired", {
    title: "Documents dépassés",
    description: "Les documents présentés ici auraient du être signés depuis 3 à 30 jours",
});
sectionMap.set("to-be-signed", {
    title: "Documents à signer",
    description: "Les documents de cette liste ne sont pas à signer dans les 2 jours",
});
sectionMap.set("urgent", {
    title: "Documents urgents",
    description: "Les documents de cette liste sont à signer dans les 2 jours",
});
sectionMap.set("to-be-approved", {
    title: "Documents à valider",
    description: "Les documents de cette liste sont en attente de validation",
});
sectionMap.set("draft", {
    title: "Brouillons",
    description: "Les documents de cette liste sont des brouillons",
});
sectionMap.set("refused", {
    title: "Documents Refusés",
    description: "Les documents de cette liste ont été refusés",
});
sectionMap.set("signed", {
    title: "Documents Signés",
    description: "Les documents de cette liste ont été signés",
});
sectionMap.set("canceled", {
    title: "Documents Résiliés",
    description: "Les documents de cette liste ont été annulés",
});